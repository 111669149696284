import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  toasts: [],
  isLoading: false,
  permissions: [],
  parameters: [],
  parameter: ''
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  },
  get (state, variable) {
    return state[variable];
  },
  clear (state) {
    state.toasts = [];
  },
  toast (state, data) {
    if(data.color == "error"){
      data.color = "danger";
    }
    state.toasts.push({
      autohide: 3000,
      closeButton: true,
      fade: true,
      position: 'top-right',
      header: 'Notificación del Sistema',
      color: data.color,
      content: data.message
    })
  },
  loading (state) {
    state.isLoading = true;
  },
  loaded (state) {
    state.isLoading = false;
  },
  parameters (state, parameters) {
    state.parameters = parameters;
  },
  getParameter (state, variable) {
    let element;
    Object.entries(state.parameters).forEach(([key,value]) => {
        if(value.name+"" === variable+""){
            element = value;
        }
    });
    
    if(element !== undefined){
      state.parameter = element.value
      return element.value;
    }
    
    console.log("Parametro no encontrado: "+variable);
  }
}

export default new Vuex.Store({
  state,
  mutations
})